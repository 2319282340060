const baseStrings = {
  /** Routes */
  'routes.appInit': '/',
  'routes.review': '/booking-review',
  'routes.registration': '/registration',
  'routes.confirm': '/confirm',
  'routes.checkinpayment': '/checkinpayment',
  'routes.confirmpayment': '/confirmpayment',
  'routes.hdfForm': '/hdf-form',
  'routes.search': '/search',
  'routes.selectDocumentType': '/select-document-type',
  'routes.takeDocumentPhoto': '/take-document-photo',
  'routes.addGuest': '/add-guest',

  /** Function Name */
  'review.menu.name': 'review',
  'hdfForm.menu.name': 'hdfForm',
  'registration.menu.name': 'registration',
  'confirm.menu.name': 'confirm',
  'error.menu.name': 'error',
  'search.menu.name': 'search',

  /** Page content */
  'home.title': 'Home',
  'home.content': 'Thank you for visiting this multi-language routing example.',

  'summary.title': 'Summary',
  'summary.content': 'Read more about the article on {medium} or {devto}.',

  'loading.title': 'Loading...',
  'loading.content': 'Loading your booking information... Please wait.',

  'error.title': 'Error',
  'error.content': 'Page not found.',
  'error.required': 'is required',
  'error.dateOfBirth.day': 'Invalid day',
  'error.message2':
    'System error occurred, please contact system admin for detail.',
  'error.message.aleadySubmit':
    'You have registered the information already. Thank You!',
  'error.message.notFound': 'Booking not found!',
  'error.message.urlInvalid': 'The URL is no longer valid!',
  'error.message.flightNumber': 'Flight No. should be like CX231',
  'error.message.flightArrivalTime': 'Please input a valid time',
  'error.message.preference': 'Please choose your preference',

  /** Links */
  'links.medium':
    'https://medium.com/prototyped/multi-language-routing-in-react-d7eb7a0688e9',
  'links.dev.to': 'https://dev.to/prototyp/multi-language-routing-in-react-k9l',

  /** Payment T&C */
  'payment.t&c':
    'I agree that my liability for this bill is not waived and agree to be held personally liable to pay the full amount of these charges.',

  'payment.noPaymentMethod': 'No payment method available',

  /** Choice Before Pay Room Rate */
  'choice.roomrateTitleOptional':
    'To shorten the check-in process time when you arrive at the hotel, do you want to pay for room rate now?',
  'choice.roomrateTitleCompulsory':
    'To enhance your check-in experience, please proceed the room payment for your reservation. Thank you!',
  'choice.roomrate.no':
    'No, I pay for the roomrate when I arrive at the hotel.',
  'choice.roomrate.title': 'Roomrate Breakdown',

  /** Choice */
  'choice.depositTitleOptional':
    'To shorten the check-in process time when you arrive at the hotel, do you want to make deposit payment now?',
  'choice.depositTitleCompulsory':
    'To shorten the check-in process time when you arrive at the hotel, please make deposit payment now.',
  'choice.yes': 'Yes',
  'choice.no': 'No, I pay for the deposit when I arrive at the hotel.',
  'choice.deposit.title': 'Deposit Breakdown',
  'choice.deposit': 'Deposit',
  'choice.roomrate': 'Room Rate',
  'choice.total': 'Total',
  'choice.warning': 'You must tick to proceed.',
  'choice.askSaveCreditCard':
    'I authorize hotel to charge my credit card for purchases related to goods and services. I agree that my information may be saved by the merchant for future payments and understand that this can be revoked at any time with request.',

  /** CheckInPayment */
  'checkinpayment.title': 'Please make the room payment now',
  'checkinpayment.ok': 'OK',
  'checkinpayment.selectpaymentmethod': 'Please select a payment method',
  'checkinpayment.useSavedCard': 'Pay with the saved credit card',
  'checkinpayment.success': 'Payment is completed. Thank you!',
  'checkinpayment.merchantRef': 'Reference Number: ',
  'checkinpayment.mini.success':
    'The payment for your reservation is Successfully Done.',
  'checkinpayment.mini.success2':
    'We look forward to welcoming you and remain committed to making your stay a smooth and memorable experience.',
  'checkinpayment.mini.success3': 'See You Soon!',
  'checkinpayment.error': 'Transaction unsuccessful, please try again',
  'checkinpayment.bankError': 'Transaction rejected, please contact your bank',
  'checkinpayment.invoice': 'Invoice is sent to your email.',
  'checkinpayment.continue': 'Continue',
  'checkinpayment.pleasescanqrcode': 'Please use the app to scan the QR code',
  'checkinpayment.payInNewTab': 'Please finish the payment on the new tab.',
  'checkinpayment.payInNewTabNotOpened':
    'If the new tab is not opened automatically, please click below button.',
  'checkinpayment.startPayment': 'Start Payment',

  /** Review */
  'review.title': 'Please review your booking detail and click "Confirm"',
  'review.confirm.no': 'Confirmation No.',
  'review.guest.name': 'Guest Name',
  'review.checkin.date': 'Stay Dates',
  'review.reserved.room.type': 'Reserved Room Type',
  'review.total.room.rate': 'Total room rate',
  'review.preferences': 'Preferences',
  'review.note':
    'Please make sure your booking name is matched with your ID/passport. Kind contact us at resvn@royalplaza.com.hk if you have any questions.',
  'review.video.note':
    'Please click {play} button to watch introduction {video} before you start the online registration.',
  'review.video.play': 'PLAY',
  'review.video.video': 'VIDEO',
  'review.room.type.RSK': 'Superior Room',
  'review.room.type.RST': 'Superior Room',
  'review.room.type.RDK': 'Deluxe Room',
  'review.room.type.RDT': 'Deluxe Room',
  'review.room.type.RDH': 'Deluxe Room',
  'review.room.type.RPK': 'Premier Room',
  'review.room.type.RPT': 'Premier Room',
  'review.room.type.RPH': 'Premier Room',
  'review.room.type.CDK': 'Crown Deluxe Room',
  'review.room.type.CDT': 'Crown Deluxe Room',
  'review.room.type.CDH': 'Crown Deluxe Room',
  'review.room.type.CPK': 'Crown Premier Room',
  'review.room.type.CPT': 'Crown Premier Room',
  'review.room.type.CPH': 'Crown Premier Room',
  'review.room.type.STK': 'Studio Suite',
  'review.room.type.STH': 'Studio Suite',
  'review.room.type.SJK': 'Junior Suite',
  'review.room.type.SJT': 'Junior Suite',
  'review.room.type.SJH': 'Junior Suite',
  'review.room.type.SDK': 'Deluxe Suite',
  'review.room.type.SDH': 'Deluxe Suite',
  'review.room.type.SRK': 'Suite Royale',
  'review.room.type.SPK': 'Penthouse Suite',
  'review.room.type.TDK': 'Sky Deluxe Room',
  'review.room.type.TDH': 'Sky Deluxe Room',
  'review.room.type.THK': 'Sky Harbour Room',
  'review.room.type.THQ': 'Sky Harbour Room',
  'review.room.type.TSK': 'Sky Side Harbour View Room',
  'review.room.type.TSQ': 'Sky Side Harbour View Room',
  'review.room.type.HJH': 'Sky Junior Suite',
  'review.room.type.HVS': 'Sky Harbour Suite',
  'review.room.type.DBD': 'Superior Queen',
  'review.room.type.TWD': 'Superior Twin',
  'review.room.type.QEA': 'Deluxe Queen',
  'review.room.type.TWA': 'Deluxe Twin',
  'review.room.type.QEE': 'Premium Deluxe Queen',
  'review.room.type.TWE': 'Premium Deluxe Twin',
  'review.room.type.KHC': 'Family Room',
  'review.room.type.KHA': 'Premium Family Room',
  'review.room.type.QEC': 'Premium Pullman Queen',
  'review.room.type.TWC': 'Premium Pullman Twin',
  'review.room.type.QEAEF': 'Deluxe Executive Queen',
  'review.room.type.KGAEF': 'Premium Deluxe Executive Queen',
  'review.room.type.SKC': 'Park Lane Suite',
  'review.room.type.SUP': 'Superior Double Room',
  'review.room.type.AWM': 'Anti-window (4 Ft)',
  'review.room.type.TD': 'Tower Double',
  'review.room.type.DLX': 'Deluxe Room',
  //DWC
  'review.room.type.SUD': 'Superior Room',
  'review.room.type.SUH': 'Superior Room',
  'review.room.type.SUK': 'Superior Room',
  'review.room.type.SUT': 'Superior Room',
  'review.room.type.GCUD': 'Superior Room with Clear Window',
  'review.room.type.GCUK': 'Superior Room with Clear Window',
  'review.room.type.GCUT': 'Superior Room with Clear Window',
  'review.room.type.PDEQ': 'Premier Causeway Bay City Room',
  'review.room.type.PDED': 'Premier Causeway Bay City Room',
  'review.room.type.PDEK': 'Premier Causeway Bay City Room',
  'review.room.type.PDET': 'Premier Causeway Bay City Room',
  'review.room.type.PDCK': 'Premier Course View Room',
  'review.room.type.PDCQ': 'Premier Course View Room',
  'review.room.type.PDCT': 'Premier Course View Room',
  'review.room.type.PDCD': 'Premier Course View Room',
  'review.room.type.DTR': 'Deluxe Triple Room',
  'review.room.type.DFQ': 'Family Quad Room',
  'review.room.type.EFQ': 'Family Quad Room',
  'review.room.type.ESK': 'Executive Suite Room',
  'review.room.type.EST': 'Executive Suite Room',

  /** Confirm */
  'confirm.title': 'Pre-arrival Registration Completed',
  'confirm.reminder':
    'You may now use Guest Services Robot when you arrive at our hotel.',
  'confirm.reminder.6641bd847063233124b3d922':
    'You may now use the below QR Code at the Self-Service Kiosk when you arrive at EAST.',
  'confirm.reminder2': 'Please show the Check-in QR Code upon check in.',
  'confirm.reminder2.6641bd847063233124b3d922': ' ',
  'confirm.thankyou': 'Thank you and see you soon!',
  'confirm.deposit':
    'To speed up check-in process, no deposit is needed for fully prepaid rooms. Any additional charges must be paid directly, as they cannot be billed to the room.',
  'confirm.qrcode.err': 'Invalid QR Code value found.',
  'confirm.notice.title': 'SPECIAL NOTICE:',
  'confirm.notice.covid19.title': 'COVID-19 Prevention Measures',
  'confirm.notice.covid19.detail': 'For further details, please see ',
  'confirm.notice.covid19.link':
    'https://www.royalplaza.com.hk/en/precautionary-measures-for-novel-coronavirus/',
  'confirm.notice.policy.title': 'Privacy Policy Statement and Disclaimer',
  'confirm.notice.policy.detail': 'For further details, please see ',
  'confirm.notice.policy.link':
    'https://www.royalplaza.com.hk/en/disclaimer-privacy-statement/',

  /** Registration Page content */
  'reg.title': 'Pre-Arrival Registration',
  'reg.reminder':
    'Please fill in your personal details below and click "Confirm"',
  'reg.wechat.reminder': 'Please use your WeChat to scan below QR code.',
  'reg.firstName': 'First Name',
  'reg.lastName': 'Last Name',
  'reg.email': 'Personal Email',
  'reg.address1': 'Address Line 1',
  'reg.address': 'Address',
  'reg.address2': 'Address Line 2',
  'reg.pleaseSelect': 'Please Select',
  'reg.country': 'Country/Region',
  'reg.nationality': 'Nationality',
  'reg.zipCode': 'Zip Code',
  'reg.arrivalHrs': 'Expected arrival time',
  'reg.flightArrival.time': 'Flight Arrival Time / Estimated Arrival Time',
  'reg.arrival.hr': 'Hour',
  'reg.arrival.min': 'Minutes',
  'reg.arrival.subject': 'Subject to availablity',
  'reg.evoucher':
    'Accept Hotel Promotion and Special Offer (Via e-mail, SMS and social media)',
  'reg.evoucher.yes/no': 'Yes/No',
  'reg.evoucher.yes': 'Yes',
  'reg.evoucher.no': 'No',
  'reg.tel': 'Mobile',
  'reg.passportNumber': 'Passport Number',
  'reg.dateOfBirth': 'Date of Birth',
  'reg.flightNumber': 'Flight No.',
  'reg.flightArrivalTime': 'Flight Arrival Time / Estimated Arrival Time',
  'reg.method.inst.msg': 'Method of instant messaging',
  'reg.method.inst.msg.select': 'Select Method',
  'reg.method.inst.msg.sms': 'SMS',
  'reg.method.inst.msg.email': 'Email',
  'reg.method.inst.msg.whatsapp': 'Whatsapp',
  'reg.method.inst.msg.wechat': 'WeChat',

  /* HDF form things */
  'hdf.ask': 'Fill Health Declaration Form?',
  'hdf.title': 'Health Declaration Form',
  'hdf.highRiskWarning.title': 'Review your response',
  'hdf.highRiskWarning.message':
    'Please review or contact Hotel for further assistance',
  'hdf.sign.title': 'Please Sign and Confirm',
  'hdf.sign.text': 'Sign Here:',
  'hdf.sign.clear': 'Clear',

  'hdf.btn.fill': 'Fill',
  'hdf.btn.addGuest': 'Add Guest',
  'hdf.btn.signAndConfirm': 'Sign & Submit',
  'hdf.btn.submitForm': 'Submit',
  'hdf.btn.back': 'Back',
  'hdf.btn.backToReview': 'Continue registration',

  'hdf.guestName': 'Guest Name',
  'hdf.accompanyName': 'Accompany',

  'hdf.familyName': 'Family Name',
  'hdf.firstName': 'First Name',
  'hdf.dateOfBirth': 'Date of Birth',
  'hdf.sex': 'Sex',
  'hdf.sex.m': 'M',
  'hdf.sex.f': 'F',
  'hdf.passport': 'Passport/Identity Card No.',
  'hdf.nationality': 'Nationality / Region',
  'hdf.comeFrom': 'Travel From',
  'hdf.arrivalDate': 'Arrival Date',
  'hdf.placesVisitedInSevenDays':
    'Please list the countries / regions and cities visited in the past 7 days',
  'hdf.contactWithCovid':
    'Have you had history of contact with any person suffering form suspected or confirmed Severe Respiratory Disease associated with a novel infectous agent infection in the past 7 days?',
  'hdf.contactWithCovid.yes': 'Yes',
  'hdf.contactWithCovid.no': 'No',

  'hdf.symptom': 'Please Select The Symptom If You Have Any.',
  'hdf.symptom.no': 'No',
  'hdf.symptom.fever': 'Fever',
  'hdf.symptom.breathingDifficulty': 'Breathing Difficulty',
  'hdf.symptom.cough': 'Cough',
  'hdf.symptom.diarrhoea': 'Diarrhoea',
  'hdf.symptom.headache': 'Headache',
  'hdf.symptom.musclePain': 'Muscle Pain',
  'hdf.symptom.runnyNose': 'Runny Nose',
  'hdf.symptom.soreThroat': 'Sore Throat',
  'hdf.symptom.stuffyNose': 'Stuffy Nose',
  'hdf.symptom.vomiting': 'Vomiting',
  'hdf.contact': 'Ways Of Contact For The Next 7 Days',
  'hdf.contact.phone': 'Tel. / Mobile Phone',
  'hdf.email': 'Email',
  'hdf.address': 'Contact Address',

  'btn.confirm': 'Confirm',
  'btn.hdf.form': 'HDF form',
  'btn.reset': 'Reset',
  'btn.cancel': 'Cancel',
  'btn.identityCard': 'Identity Card',
  'btn.passport': 'Passport',
  'btn.take': 'Take',
  'btn.upload': 'Upload',
  'btn.capture': 'Capture',
  'btn.retake': 'Retake',
  'btn.reUpload': 'Re-upload',
  'btn.retry': 'Retry',
  'btn.register': 'Register',
  'btn.remove': 'Remove',
  'btn.addGuest': 'Add Guest',

  'country.AF': 'Afghanistan',
  'country.AL': 'Albania',
  'country.DZ': 'Algeria',
  'country.AS': 'American Samoa',
  'country.AD': 'Andorra',
  'country.AO': 'Angola',
  'country.AI': 'Anguilla/St Kitts',
  'country.AG': 'Antigua and Barbuda',
  'country.AR': 'Argentina',
  'country.AM': 'Armenia',
  'country.AW': 'Aruba',
  'country.AU': 'Australia',
  'country.AT': 'Austria',
  'country.AZ': 'Azerbaijan',
  'country.BS': 'Bahamas',
  'country.BH': 'Bahrain',
  'country.BD': 'Bangladesh',
  'country.BB': 'Barbados',
  'country.BY': 'Belarus',
  'country.BE': 'Belgium',
  'country.BZ': 'Belize',
  'country.BJ': 'Benin',
  'country.BM': 'Bermuda',
  'country.BT': 'Bhutan',
  'country.BO': 'Bolivia',
  'country.BQ': 'Bonaire,Saba,St Eustatius',
  'country.BA': 'Bosnia Herzegovina',
  'country.BW': 'Botswana',
  'country.BV': 'Bouvet Island',
  'country.BR': 'Brazil',
  'country.IO': 'British Indian Ocean Territory',
  'country.BN': 'Brunei Darussalam',
  'country.BG': 'Bulgaria',
  'country.BF': 'Burkina Fasa',
  'country.BI': 'Burundi',
  'country.KH': 'Cambodia',
  'country.CM': 'Cameroon',
  'country.CA': 'Canada',
  'country.CV': 'Cape Verde',
  'country.KY': 'Cayman Islands',
  'country.CF': 'Central African Republic',
  'country.TD': 'Chad',
  'country.CL': 'Chile',
  'country.CN': 'China',
  'country.CX': 'Christmas Island',
  'country.CC': 'Cocos (Keeling) Islands',
  'country.CO': 'Colombia',
  'country.KM': 'Comoros',
  'country.CG': 'Congo',
  'country.CD': 'Congo, The Democratic Republic of the',
  'country.CK': 'Cook Island',
  'country.CR': 'Costa Rica',
  'country.CI': "Cote D'Ivoire",
  'country.HR': 'Croatia',
  'country.CU': 'Cuba',
  'country.CW': 'Curacao',
  'country.CY': 'Cyprus',
  'country.CZ': 'Czech Republic',
  'country.DK': 'Denmark',
  'country.DJ': 'Djibouti',
  'country.DM': 'Dominica',
  'country.DO': 'Dominican Republic',
  'country.TP': 'East Timor',
  'country.EC': 'Ecuador',
  'country.EG': 'Egypt',
  'country.SV': 'El Salvador',
  'country.GQ': 'Equatorial Guinea',
  'country.ER': 'Eritrea',
  'country.EE': 'Estonia',
  'country.ET': 'Ethiopia',
  'country.FK': 'Falkland Islands',
  'country.FO': 'Faroe Islands',
  'country.FJ': 'Fiji',
  'country.FI': 'Finland',
  'country.FR': 'France',
  'country.GF': 'French Guiana',
  'country.PF': 'French Polynesia',
  'country.TF': 'French Souther Territories',
  'country.GA': 'Gabon',
  'country.GM': 'Gambia',
  'country.GE': 'Georgia',
  'country.DE': 'Germany',
  'country.GH': 'Ghana',
  'country.GI': 'Gibraltar',
  'country.GB': 'Great Britain',
  'country.GR': 'Greece',
  'country.GL': 'Greenland',
  'country.GD': 'Grenada',
  'country.GP': 'Guadeloupe/St Martin',
  'country.GU': 'Guam',
  'country.GT': 'Guatemala',
  'country.GN': 'Guinea',
  'country.GW': 'Guinea Bissau',
  'country.GY': 'Guyana',
  'country.HT': 'Haiti',
  'country.HM': 'Heard and McDonald Islands',
  'country.HN': 'Honduras',
  'country.HK': 'Hong Kong SAR',
  'country.HU': 'Hungary',
  'country.IS': 'Iceland',
  'country.IN': 'India',
  'country.ID': 'Indonesia',
  'country.IR': 'Iran',
  'country.IQ': 'Iraq',
  'country.IE': 'Ireland',
  'country.IM': 'Isle Of Man',
  'country.IL': 'Israel',
  'country.IT': 'Italy',
  'country.JM': 'Jamaica',
  'country.JP': 'Japan',
  'country.JO': 'Jordan',
  'country.KZ': 'Kazakhstan',
  'country.KE': 'Kenya',
  'country.KI': 'Kiribati',
  'country.KR': 'Korea, Republic Of',
  'country.KP': "Korea,Democratic People's Republic of",
  'country.KW': 'Kuwait',
  'country.KG': 'Kyrgyzstan',
  'country.LA': 'Laos',
  'country.LV': 'Latvia',
  'country.LB': 'Lebanon',
  'country.LS': 'Lesotho',
  'country.LR': 'Liberia',
  'country.LY': 'Libyan Arab Jamahiriya',
  'country.LI': 'Liechtenstein',
  'country.LT': 'Lithuania',
  'country.LU': 'Luxembourg',
  'country.MO': 'Macau SAR',
  'country.MK': 'Macedonia',
  'country.MG': 'Madagascar',
  'country.MW': 'Malawi',
  'country.MY': 'Malaysia',
  'country.MV': 'Maldives',
  'country.ML': 'Mali',
  'country.MT': 'Malta',
  'country.MH': 'Marshall Islands',
  'country.MQ': 'Martinique',
  'country.MR': 'Mauritania',
  'country.MU': 'Mauritius',
  'country.YT': 'Mayotte',
  'country.MX': 'Mexico',
  'country.FM': 'Micronesia, Federated States of',
  'country.MI': 'Midway Islands',
  'country.MD': 'Moldova',
  'country.MC': 'Monaco',
  'country.MN': 'Mongolia',
  'country.ME': 'Montenegro',
  'country.MS': 'Montserrat',
  'country.MA': 'Morocco',
  'country.MZ': 'Mozambique',
  'country.MM': 'Myanmar',
  'country.NA': 'Namibia',
  'country.NR': 'Nauru',
  'country.NP': 'Nepal',
  'country.NL': 'Netherlands',
  'country.AN': 'Netherlands Antilles',
  'country.NC': 'New Caledonia',
  'country.NZ': 'New Zealand',
  'country.NI': 'Nicaragua',
  'country.NE': 'Niger',
  'country.NG': 'Nigeria',
  'country.NU': 'Niue',
  'country.NF': 'Norfolk Island',
  'country.MP': 'Northern Mariana Islands',
  'country.NO': 'Norway',
  'country.OM': 'Oman',
  'country.PK': 'Pakistan',
  'country.PW': 'Palau',
  'country.PA': 'Panama',
  'country.PG': 'Papua New Guinea',
  'country.PY': 'Paraguay',
  'country.PE': 'Peru',
  'country.PH': 'Philippines',
  'country.PN': 'Pitcairn',
  'country.PL': 'Poland',
  'country.PT': 'Portugal',
  'country.PR': 'Puerto Rico',
  'country.QA': 'Qatar',
  'country.RE': 'Reunion',
  'country.RO': 'Romania',
  'country.RU': 'Russia',
  'country.RQ': 'Russian Federation',
  'country.RW': 'Rwanda',
  'country.SH': 'Saint Helena',
  'country.KN': 'Saint Kitts and Nevis',
  'country.LC': 'Saint Lucia',
  'country.PM': 'Saint Pierre And Miquelon',
  'country.VC': 'Saint Vincent And The Grenadines',
  'country.WS': 'Samoa',
  'country.SM': 'San Marino',
  'country.ST': 'Sao Tome and Principe',
  'country.SA': 'Saudi Arabia',
  'country.SN': 'Senegal',
  'country.RS': 'Serbia',
  'country.SC': 'Seychelles',
  'country.SL': 'Sierra Leone',
  'country.SG': 'Singapore',
  'country.SK': 'Slovakia',
  'country.SI': 'Slovenia',
  'country.SB': 'Solomon Islands',
  'country.SO': 'Somalia',
  'country.ZA': 'South Africa',
  'country.GS': 'South Georgia and the South Sandwich Islands',
  'country.ES': 'Spain',
  'country.LK': 'Sri Lanka',
  'country.SD': 'Sudan',
  'country.SR': 'Suriname',
  'country.SJ': 'Svalbard and Jan Mayen Islands',
  'country.SZ': 'Swaziland',
  'country.SE': 'Sweden',
  'country.CH': 'Switzerland',
  'country.SY': 'Syria',
  'country.TW': 'Taiwan',
  'country.TJ': 'Tajikistan',
  'country.TZ': 'Tanzania',
  'country.TH': 'Thailand',
  'country.TG': 'Togo',
  'country.TK': 'Tokelau',
  'country.TO': 'Tonga',
  'country.TT': 'Trinidad And Tobago',
  'country.TN': 'Tunisia',
  'country.TR': 'Turkey',
  'country.TM': 'Turkmenistan',
  'country.TC': 'Turks and Caicos Islands',
  'country.TV': 'Tuvalu',
  'country.UG': 'Uganda',
  'country.UA': 'Ukraine',
  'country.AE': 'United Arab Emirates',
  'country.US': 'United States',
  'country.UM': 'United States Minor Outlying Islands',
  'country.XX': 'Unknown',
  'country.UY': 'Uruguay',
  'country.UZ': 'Uzbekistan',
  'country.VU': 'Vanuatu',
  'country.VA': 'Vatican City State',
  'country.VE': 'Venezuela',
  'country.VN': 'Vietnam',
  'country.VG': 'Virgin Islands, British',
  'country.VI': 'Virgin Islands, US',
  'country.WF': 'Wallis and Futuna Islands',
  'country.EH': 'Western Sahara',
  'country.YE': 'Yemen',
  'country.ZR': 'Zaire',
  'country.ZM': 'Zambia',
  'country.ZW': 'Zimbabwe',

  'country.AX': 'Åland Islands',
  'country.AC': 'Ascension Island',
  'country.BL': 'Saint Barthélemy',
  'country.GG': 'Guernsey',
  'country.JE': 'Jersey',
  'country.MF': 'Saint Martin (French part)',
  'country.PS': 'Palestinian Territory, Occupied',
  'country.TA': 'Tristan da Cunha',
  'country.TL': 'Timor-Leste',
  'country.XK': 'Kosovo',
  'country.SS1': 'South Sudan',
  'country.SX1': 'Sint Maarten (Dutch part)',

  /** Document */
  'selectDocumentType.title': 'Select Passport or ID Card to Submit',
  'takeDocumentPhoto.title': 'Submit ID card (Front)',
  'takeDocumentPhoto.noteTitle': 'Few pointers to take note',
  'takeDocumentPhoto.note1': '1. Environment is not dark or dimmed',
  'takeDocumentPhoto.note2': '2. Text and/or image are visible and not cut-off',
  'takeDocumentPhoto.note3': '3. Image is clear and not blurred',
  'takeDocumentPhoto.takePhotoInstruction1':
    'Please adjust the image to ensure all information is within the box.',
  'takeDocumentPhoto.takePhotoInstruction2':
    'fill the rectangle with your ID card',
  'takeDocumentPhoto.uploadSuccess': 'Upload Success',
  'takeDocumentPhoto.uploadFail': 'Upload Fail',
  'takeDocumentPhoto.uploading': 'Uploading...Please wait',

  /** Add Guest */
  'accompanyGuest.title': 'Accompany Guest',
  'accompanyGuest.label.guestName': 'Guest Name',
  'accompanyGuest.label.lastName': 'Last Name',
  'accompanyGuest.label.firstName': 'First Name',
  'accompanyGuest.guestStatus.completed': 'Completed',
  'accompanyGuest.guestStatus.inProgress': 'In Progress',
  'accompanyGuest.removeGuest.confirmation':
    'Are you sure to remove this guest?',

  /** Modal */
  'modal.title.removeGuest': 'Remove Guest',
};

export type LanguageStrings = typeof baseStrings;
export const en = baseStrings;
